<template>
  <footer
    id="Southfarthing"
    itemscope
    itemtype="https://schema.org/WPFooter"
  >
    <div class="v-container">
      <div class="v-row">
        <div class="v-col-md-2 v-col-6 v-offset-3 v-offset-md-0 v-mb-md v-logo-order">
          <div class="v-logo">
            <arora-nuxt-link
              itemscope
              :href="appConfig.VueSettingsPreRun.Links.MainLink"
              :label="translate('headerPage.homePage')"
              itemtype="https://schema.org/Brand"
            >
              <arora-nuxt-image
                disable-resize
                preload
                :alt="translate('headerPage.homePage')"
                :image="appConfig.VueSettingsPreRun.FooterLogo"
                itemprop="logo"
              />
            </arora-nuxt-link>
          </div>
        </div>
        <div class="v-col-lg-3 v-col-md-3 v-mb-md v-links-order">
          <div
            v-if="!stringIsNullOrWhitespace(appConfig.VueSettingsPreRun.FooterExternalLinksTitle)"
            class="v-title-links v-mb-xs"
            v-html="sanitize(appConfig.VueSettingsPreRun.FooterExternalLinksTitle)"
          />
          <ul class="v-row v-links-footer--list">
            <li
              v-for="(link, index) in externalLinksFooter"
              :key="`v-links-footer-${index}`"
              class="v-col-6 v-links-footer--item v-p-0 v-mb-xs"
            >
              <arora-nuxt-link
                class-name="v-link"
                :external-link="!link.Url.startsWith('/')"
                :href="link.Url"
                :label="sanitize(link.Name)"
                :open-in-new-tab="!link.Url.startsWith('/')"
                active-class="v-active"
              />
            </li>
            <li
              v-if="appConfig.RestaurantSettingsPreRun.ContentAvailable.HasArticles"
              class="v-col-6 v-links-footer--item v-p-0 v-mb-xs"
            >
              <arora-nuxt-link
                class-name="v-link"
                :href="appConfig.VueSettingsPreRun.Links.ArticlesLink"
                :label="translate('footerPage.articles')"
                active-class="v-active"
              />
            </li>
          </ul>
          <div
            v-if="isExtraSmall"
            class="v-col-12"
          >
            <hr class="v-opacity-20" />
          </div>
        </div>

        <div class="v-col-lg-2 v-col-md-3 v-col-sm-6 v-mb-md v-payment-card-order">
          <common-payment-cards />

          <div
            v-if="appConfig.VueSettingsPreRun.FooterShowSocial"
            class="v-social-link-footer v-d-flex v-flex-column"
          >
            <common-social-networks :text="translate('footerPage.titleSocialNetworksLinks')" />
          </div>
        </div>

        <div class="v-col-lg-5 v-col-md-4 v-col-sm-6 v-mb-md v-apps-order">
          <div
            v-if="appConfig.VueSettingsPreRun.FooterShowApps"
            class="v-block-apps"
          >
            <div
              class="v-bg"
              :class="{ 'v-bg--small': appConfig.VueSettingsPreRun.FooterEnableAppQRCode }"
            />
            <div class="v-row v-content">
              <div class="v-col-lg-7 v-col-12 v-p-0">
                <div
                  class="v-title-apps v-subtitle v-mb-xs"
                  v-html="translate('footerPage.appsDownload')"
                />
                <div
                  class="v-desc-apps v-mb-sm"
                  v-html="translate('footerPage.appsDescription')"
                />
                <common-apps />
              </div>
              <div
                v-if="isDesktopVersion"
                class="v-col-lg-5 v-position-relative"
              >
                <div
                  :class="[
                    appConfig.VueSettingsPreRun.FooterEnableAppQRCode &&
                    !stringIsNullOrWhitespace(appConfig.VueSettingsPreRun.AppDownloadQRBase64)
                      ? 'v-footer-qr-code'
                      : 'v-img-box'
                  ]"
                >
                  <img
                    v-if="
                      appConfig.VueSettingsPreRun.FooterEnableAppQRCode &&
                      !stringIsNullOrWhitespace(appConfig.VueSettingsPreRun.AppDownloadQRBase64)
                    "
                    class="v-img-fluid"
                    :alt="translate('qrPayPopup.overQr')"
                    :src="`data:image/png; base64, ${appConfig.VueSettingsPreRun.AppDownloadQRBase64}`"
                  />
                  <arora-nuxt-image
                    v-else
                    :alt="translate('footerPage.homePage')"
                    :image="appConfig.VueSettingsPreRun.FooterPhonePic"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="v-col-12 v-line-order">
          <hr class="v-opacity-20" />
        </div>

        <div class="v-col-sm-6 v-col-12 v-legal-order">
          <div class="v-block-legal-professional v-d-flex">
            <div
              v-if="appConfig.VueSettingsPreRun.FooterShowCopyright"
              class="v-copyright v-mr-md"
            >
              <meta
                :content="`${currentYear}`"
                itemprop="copyrightYear"
              />
              <meta
                :content="appConfig.VueSettingsPreRun.CopyRightOrgName"
                itemprop="copyrightHolder"
              />
              <span v-html="copyrightYears" />
            </div>
            <div class="v-privacy-politic">
              <arora-nuxt-link
                class-name="v-link"
                :href="appConfig.VueSettingsPreRun.Links.AgreementLink"
                :label="translate('footerPage.privacy')"
                active-class="v-active"
              />
            </div>
          </div>
        </div>

        <div class="v-col-sm-6 v-col-12 v-holder-order">
          <div
            v-if="appConfig.VueSettingsPreRun.FooterShowLegalLogo"
            class="v-copyright-holder"
            :class="{
              'v-opacity-20': appConfig.VueSettingsPreRun.FooterLegalLogoVariant === 'arora-default'
            }"
          >
            <arora-nuxt-link
              class-name="v-fz-0"
              open-in-new-tab
              :href="linkSite"
              :label="linkSite"
            >
              <icon-footer-arora />
            </arora-nuxt-link>
          </div>
        </div>
      </div>
    </div>

    <div class="v-block-anim">
      <div
        class="v-city-anim"
        :style="footerAnimBackground"
      >
        <div class="v-container">
          <div class="v-car-anim">
            <arora-nuxt-image
              :alt="translate('footerPage.homePage')"
              :image="appConfig.VueSettingsPreRun.FooterAnimCarPic"
            />
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import type { FooterCommon } from '~types/props'

import { useCommon, useWindowSize } from '@arora/common'

defineProps<FooterCommon>()

const appConfig = useAppConfig()
const { stringIsNullOrWhitespace } = useCommon()
const { translate, sanitize } = useI18nSanitized()
const { isExtraSmall, isDesktopVersion } = useWindowSize()
const currentYear = new Date().getFullYear()

const footerAnimBackground = computed<string>(() => {
  if (appConfig.VueSettingsPreRun.FooterAnimBackground) {
    return `background-image: url(${appConfig.VueSettingsPreRun.FooterAnimBackground.Path.replaceAll('\\', '/')});`
  }
  return 'background-image: none;'
})
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

#Southfarthing {
  padding-top: 75px;
  padding-bottom: 0;
  margin-top: 4rem;
  @include mixins.sm {
    padding-top: 20px;
    padding-bottom: 77px;
  }
  .v-title-apps {
    font-weight: 600;
  }
  .v-title-links {
    font-size: 1.15rem;
    font-weight: 600;
  }
  .v-links-footer {
    &--list {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    &--item {
      @include mixins.trans;

      overflow: hidden;
      white-space: pre-wrap;
      text-overflow: ellipsis;

      .v-link {
        font-size: 1rem;
        opacity: 0.4;
      }
      .v-active {
        opacity: 1;
        font-weight: 600;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .v-icon-payment--item {
    flex: 0 0 33.3%;
    max-width: 33.3%;
    @include mixins.sm {
      flex: 0 0 22%;
      max-width: 22%;
    }
  }
  .v-social-link-footer {
    .v-social-networks {
      flex-direction: row;
    }
  }

  .v-block-apps {
    position: relative;
    padding: 20px;
    .v-bg {
      background: variables.$FooterColor;
      opacity: 0.1;
      position: absolute;
      top: 0;
      right: -25px;
      left: -15px;
      bottom: 0;
      z-index: 0;
      border-radius: variables.$BorderRadius;
      &--small {
        right: 0;
      }
      @include mixins.sm {
        right: -15px;
        border-radius: 0;
      }
    }
    .v-content {
      position: relative;
      z-index: 1;
    }
    .v-img-box {
      height: 300px;
      position: absolute;
      bottom: calc(-1.25rem + -41px);
      overflow: hidden;
      width: 100%;
    }
  }

  .v-logo-order {
    order: 1;
  }
  .v-links-order {
    order: 2;
    @include mixins.sm {
      order: 2;
    }
  }

  .v-payment-card-order {
    order: 2;
    @include mixins.sm {
      order: 4;
    }
  }

  .v-apps-order {
    order: 3;
    @include mixins.sm {
      order: 6;
    }
  }

  .v-line-order {
    order: 5;
  }
  .v-legal-order {
    order: 6;
    @include mixins.sm {
      margin-bottom: 20px;
    }
  }
  .v-holder-order {
    order: 6;
    @include mixins.sm {
      order: 7;
    }
  }

  .v-footer-qr-code {
    .v-img-fluid {
      border-radius: max(4px, variables.$BorderRadius - 20px);
      overflow: hidden;
    }
  }

  .v-logo {
    height: 100%;

    a {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
